<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<div class="container">
    <form class="header-search-form text-minuscula">

        <div class="col-lg-12 text-center mt-3">
            <div class="row">
                <div class="col-10 text-size-14 text-size-16 text-secondary">
                    <i class="fas fa-unlock-alt text-dark mr-2"></i>
                    Iniciar Sesión
                </div>
                <div class="col-2">
                    <i class="bi bi-x-circle-fill text-danger" (click)="dismissModal('Cross click')"></i>
                </div>
            </div>
        </div>

        <hr>

        <div class="modal-body">

            <div class="form-group">
                <i class="fas fa-id-card-alt mr-2 text-dark"></i>
                <label class="text-size-14 mb-3">Usuario *</label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="nombres" [(ngModel)]="login.usuario">
            </div>

            <div class="form-group">
                <i class="fas fa-lock mr-2 text-dark"></i>
                <label class="text-size-14 mb-3">Contraseña *</label>
                <div class="div-input right-addon">
                    <i class="icn-input fas fa-eye-slash text-size-14" role="button" matSuffix (click)="login.viewPass = !login.viewPass" *ngIf="!login.viewPass"></i>
                    <i class="icn-input fas fa-eye text-size-14" role="button" matSuffix (click)="login.viewPass = !login.viewPass" *ngIf="login.viewPass"></i>
                    <input type="password" class="form-control form-control-sm rounded-pill" name="password" matInput [type]="login.viewPass ? 'password': 'text'" [(ngModel)]="login.clave">
                </div>
            </div>

            <hr>

            <div class="text-center">
                <button type="button" id="btn-login" class="btn text-size-14 btn-sm btn-block border rounded-pill" (click)="dismissModal('Administrator')" type="submit">
                    Iniciar Sesión
                </button>
            </div>

        </div>

        <br>

    </form>
</div>