<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<section class="container-fluid">
    <!-- Administrador, login usuario -->
    <div class="row text-size-12 " id="div-submenu">
        <div class="col-lg-6 pt-2 pb-2 ">
            <div class="pl-3">
                <span style="float: left;">
                    Bienvenidos a <strong>{{companyNane}}</strong> Tienda Online
                </span>
            </div>
        </div>

        <div class="col-lg-6 pt-2 pb-2 div-login-user">
            <div class="pr-3" *ngIf="!clientLogin.login">
                <div class="div-oauth" title="Ingresar como Administrador">
                    <span class="ml-3 spn-user-login" role="button" (click)="loginAdmin()">
                        Administrador
                        <i class="fas fa-user-cog ml-2"></i>
                    </span>
                </div>
                <div class="div-auth">
                    <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="loginUser()">
                        <i class="fas fa-user-lock mr-2"></i>
                        Iniciar Sesión
                    </span>
                </div>
            </div>

            <div class="pr-3" *ngIf="clientLogin.login">
                <div class="div-oauth" (click)="signOff()">
                    <span class="ml-3 spn-user-login" role="button">
                        <i class="fas fa-lock ml-2"></i>
                    </span>
                </div>
                <div class="div-auth" (click)="goClientProfile()">
                    <img src="../../../assets/img/user.jpg" class="rounded-circle mr-2" width="25px" height="25px"
                        *ngIf="!clientLogin.imagen">
                    <img src="https://drive.google.com/uc?export=view&id={{clientLogin.imagen}}"
                        class="rounded-circle mr-2" width="25px" height="25px" *ngIf="clientLogin.imagen">
                    <span class="pr-3 linea-right-blanca spn-user-login" role="button">
                        {{clientLogin.name}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Logo, buscador y carrito -->
    <!-- <div class="row text-center linea-bottom-ploma pb-2">
        <div class="col-lg-3 text-mayuscula w-100">
            <img class="img-fluid logo" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}" alt="" (click)="goHome()">
        </div>

        <div class="col-lg-6 text-minuscula w-100">
            <div class="div-search pt-2">
                <form class="form-search">
                    <input class="border" type="search" placeholder="Buscar producto ..." name="search" [(ngModel)]="searchProd">
                    <button id="btn-search" (click)="searchProduct(searchProd)">
                        <i class="fa fa-search"></i>
                    </button>
                </form>
            </div>
        </div>

        <div class="col-lg-3 text-mayuscula">
            <div class="row pt-2">
                <div class="col-6">
                    <button type="button" class="btn-circle float-right animate__animated animate__swing" id="btn-car" (click)="goShoppingCartUser()">
                        <i class="fab fa-opencart"></i>
                        <span class="ml-1">{{cartProducts.number}}</span>
                    </button>
                </div>

                <div class="col-6 linea-left-ploma">
                    <div class="row" (click)="goShoppingCartUser()">
                        <div class="col-12">
                            <span class="float-left">CARRITO</span>
                        </div>
                        <div class="col-12">
                            <span class="float-left">${{cartProducts.total | number: '1.2'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- NAVBAR 2 -->


    <div class="row " id="navBarC" style="   box-shadow: 1px 3px 4px 2px rgba(178, 177, 177, 0.3); ">
        <div class="col-lg-2 col-md-12" >
            <a class="navbar-nav p-4"   >
                <!-- <img  (click)="goHome1()" src="https://drive.google.com/uc?export=view&id={{configuracion?.imgLogo}}" style="width: 100%;height: 95px; object-fit: scale-down; "> -->
                <img  (click)="goHome1()"  [src]="urlRuta+configuracion?.imgLogo+'?alt=media'" style="width: 100%;height: 95px; object-fit: scale-down; ">
            </a>
        </div>
        <div class="col-lg-8 col-md-12 " >
            <div class="row divSelect ">
                <div class="col-12 " id="menuLGResposivo">
                    <section>
                        <nav class="divSelect">
                          <ul class="menuItems ">
                            <li>
                                <a id="optionNav" class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active"  aria-current="page"  type="button"  ><i class="bi bi-house-fill"></i> Inicio</a>

                            </li>
                            <li>
                                <a id="optionNav1" class="nav-link active" aria-current="page"  type="button" (click)="goProductsCatalogue()"  > E-commerce </a>

                            </li>
                            <li>
                                <a id="optionNav2" class="nav-link" type="button" (click)="goProductsCatalogue1()"     >Menú</a>

                            </li>
                            <li>
                                <a id="optionNav3" class="nav-link"  (click)="goInicio()">Sobre nosotros</a>

                            </li>
                            <li>
                                <a id="optionNav5" class="nav-link"  class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active" >Contáctanos</a>
                            </li>
                          </ul>
                        </nav>
                      
                      </section>
                </div>

                <div class="col-12 pb-2 " id="menuSMResposivo">
                    <ul style="text-align: center; font-weight: 500;">
                        <li><a id="optionNav" class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active"  aria-current="page"  type="button"  ><i class="bi bi-house-fill"></i> Inicio</a></li>
                        <li> <a id="optionNav1" class="nav-link active" aria-current="page"  type="button" (click)="goHome()"  > E-commerce</a></li>
                        <li><a id="optionNav2" class="nav-link" type="button" (click)="goProductsCatalogue1()"   >Menú</a></li>
                        <li><a id="optionNav3" class="nav-link"  (click)="goInicio()">Sobre nosotros</a></li>
                        <li>
                            <a id="optionNav5" class="nav-link"  class="nav-link active" [routerLink]="[ '/inicio' ]" routerLinkActive="active" >Contáctanos</a>
                        </li>
                    </ul>

                </div>

                    <div class=" col-lg-8 col-md-8 col-10  card-margin pt-1">         
                            <form  class="flex-form " [formGroup]="formBusqueda">
                                <input type="search" formControlName="campoText" id="inputSearch" class="form-control-sm" placeholder="Busca más sobre nuestros servicios">
                                <input id="btnLupa" type="submit"  class="form-control-sm" value="Buscar" (click)="searchProduct(formBusqueda.value)">                              
                              </form>  
                    </div>     
            </div>
        </div>
        <div class="col-lg-2 col-md-12 col-12 " style="padding-top: 2em; padding-bottom:2em ;" >
            <div class="row "  *ngIf="urlLogo2">
                <div class="col-lg-5 col-md-5 col-6 divSelect "  >
                    <img id="imgSMLogo"  type="button"   (click)="redirectULR2()"  [src]="urlRuta+this.logo2+'?alt=media'" style="width: 100%;height: 75px; object-fit: scale-down; border-radius: 8px; ">
 
                </div>
                <div class="col-6 col-lg-7 col-md-7 "   type="button"  (click)="goShoppingCartUser()">
                    <div class="row pt-2" >
                        <div class="col-6  " style="padding: 0em 0.65em 0em 0em;">
        
                              <button class="button-with-span rounded-circle float-right animate__animated animate__swing"  id="btn-car">
                                <span class="button-span"> {{cartProducts.number}}</span>
                                <i class="fas fa-shopping-cart" style="color:#FFFFFF; width:6; height:6;"></i>
                              </button>
                        </div>
        
                        <div class="col-6 linea-left-ploma " style="padding: 0em 0em 0em 0.65em;">
                            <div class="row" >
                                <div class="col-12">
                                    <span class="float-left fw-bold " >Carrito</span>
                                </div>
                                <div class="col-12">
                                    <span class="float-left">$ {{cartProducts.total | number: '1.2'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!urlLogo2"  type="button"  (click)="goShoppingCartUser()">
                <div class="row pt-2" >
                    
                    <div class="col-6 " style="padding: 0em 0.65em 0em 0em;">
    
                          <button class="button-with-span rounded-circle float-right animate__animated animate__swing"  id="btn-car">
                            <span class="button-span"> {{cartProducts.number}}</span>
                            <i class="fas fa-shopping-cart" style="color:#FFFFFF; width:6; height:6;"></i>
                          </button>
                    </div>
    
                    <div class="col-6 linea-left-ploma " style="padding: 0em 0em 0em 0.65em;">
                        <div class="row" >
                            <div class="col-12">
                                <span class="float-left fw-bold " >CARRITO</span>
                            </div>
                            <div class="col-12">
                                <span class="float-left">$ {{cartProducts.total | number: '1.2'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="col-lg-1 pt-4">
            <img   *ngIf="urlLogo2!=null" (click)="goHome1()"  [src]="urlRuta+this.logo2+'?alt=media'" style="width: 100%;height: 95px; object-fit: scale-down; ">

        </div>
        <div class="col-lg-2 " style="padding-top: 2em; padding-bottom:2em ;"  type="button"  (click)="goShoppingCartUser()">
            <div class="row pt-2" >
                
                <div class="col-6 " style="padding: 0em 0.65em 0em 0em;">
                      <button class="button-with-span rounded-circle float-right animate__animated animate__swing"  id="btn-car">
                        <span class="button-span"> {{cartProducts.number}}</span>
                        <i class="fas fa-shopping-cart" style="color:#FFFFFF; width:6; height:6;"></i>
                      </button>
                </div>

                <div class="col-6 linea-left-ploma " style="padding: 0em 0em 0em 0.65em;">
                    <div class="row" >
                        <div class="col-12">
                            <span class="float-left fw-bold " >CARRITO</span>
                        </div>
                        <div class="col-12">
                            <span class="float-left">$ {{cartProducts.total | number: '1.2'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>

</section>

<!-- Boton chat de whastapp -->
<app-whats-app><h1></h1></app-whats-app>
<!-- <div class="div-chat-whatsapp">
    <div class="text-right" >
        <i class="fab fa-whatsapp"></i>
        <span class="spn-info-whats text-size-14 pl-2 pr-2 pt-1 pb-1 rounded-pill text-light" role="button" (click)="contactWhatsapp(whatsappModal)">
            Contáctanos
            <i class="fas fa-chalkboard-teacher"></i>
        </span>
    </div>
</div> -->

<div class="div-btn-car" (click)="goShoppingCartUser()">
    <button type="button" class="btn btn-dark" >
        <i class="bi bi-cart-plus p-1"></i>
    </button>
</div>

<!-- Cantactar por whatsapp-->
<!-- <ng-template #whatsappModal let-modal class="modal-whatsapp" style="height: 20em;">

    <div class="modal-header header-whatsapp tipo-letra">
        <i class="fab fa-whatsapp icn-whatsapp"></i>
        <h6 class="modal-title title-whatsapp">Escríbenos a WhatsApp</h6>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')"
            >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body body-whatsapp tipo-letra">

        <div class="row cuadro-mensaje">
            <div class="col-12">
                <p class="p-mensaje">Hola, en que te podemos ayudar.</p>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">

            <div class="col-10">
                <input type="text" class="inp-mensaje" placeholder="Aquí tu mensaje ..." [(ngModel)]="textWhatsapp">
            </div>

            <div class="col-2 div-send">
                <div class="boton" (click)="modal.close('Close click')">
                    <a>
                        <i class="fas fa-angle-right"></i>
                    </a>
                </div>
            </div>

        </div>

    </div>

</ng-template> -->
