<!-- <app-submenu></app-submenu> -->
<app-header *ngIf="!isEmpty(information) && !isEmpty(configuracion) " [information]="information"
    [configuracion]="configuracion" [flagCar]="flagCarLocal"></app-header>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section class="container-fluid mt-5 pt-4 animate__animated animate__fadeIn">
    <div class="row pt-2 pb-2  ">
        <!-- Catalogo -->
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-12  text-center mt-2">
            <app-catalogo (emitGrupoSubGrupo)="recibeGrupoSubGrupo($event)"></app-catalogo>
        </div>
        <!-- Menu y Productos -->
        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-12  " *ngIf="this.allGrupos">

            <div class="bgProductos  mt-1  ">
                <div class="container-fluid bodyCatalogo ">
                    <div class="row w-100 divSelect ">
                        <div class="col-xxl-9 col-xl-8 col-lg-8 mt-2 ">
                            <div class="row w-100">
                                <div class="col-xxl-6 col-xl-6 col-lg-5 mt-1 mb-1">
                                    <app-menu></app-menu>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-7 mt-1 mb-1">
                                    <div class=" bg-light rounded rounded-pill shadow-sm mb-3">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button id="button-addon2" type="submit"
                                                    class="btn btn-link text-secondary"><i
                                                        class="fa fa-search"></i></button>
                                            </div>
                                            <input type="text" (keyup)="searchProduct($event)"
                                                placeholder="¿Qué está buscando?" aria-describedby="button-addon2"
                                                class="form-control border-0 bg-white rounded-pill">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-lg-4 col-10  mt-0 " *ngIf="flagCarLocal">
                            <div class="card bgCarProduct shadow animated bounceInLeft ">
                                <form class=" my-0 my-lg-0">
                                    <div style="display: flex; justify-content: space-around; ">
                                        <div class="div-auth  pl-1">
                                            <span class=" text-white fw-semibold"  type="click" (click)="exitLoginLocal()">
                                                Salir X
                                                <!-- <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR"  ></i> -->
                                            </span>
                                        </div>
                                        <div class="d-flex"
                                            style="height: 40px; background-color:rgb(255, 255, 255);  ">
                                            <div class="vr" style="width: 1px;"> </div>
                                        </div>
                                        <div class="btn mt-1 text-white   " (click)="openModal(modalCarritoLocal)"
                                            style="position: relative; " type="button">
                                            Mi pedido
                                            <span
                                                style="position: absolute; top:0 ; right: 0; font-weight: 600; font-size: 10px; background-color: white; color:#000000 !important "
                                                class="badge badge-pill "  >{{prods.length}}</span>
                                            <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; "> </i>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>

                    <div class=" " *ngIf="products.length>0 ">
                        <div class="  animate__animated animate__fadeIn">
                            <div class="row text-center w-100 divSelect ">
                                <div class=" col-xxl-3 col-xl-4  col-md-6 col-12 " style="margin-bottom: 1.5em;"
                                    *ngFor="let p of products; let i=index">
                                    <div class="card " style="background-color: #f7f7f7;position: relative; ">
                                        <span *ngIf="p.precioOferta > 0"
                                            class="position-absolute top-0 end-0 translate-middle badge  badgeStar rounded-pill"
                                            style="margin-top: 2em; margin-left: 1em;  ">
                                            5 <i class="bi bi-star-fill text-warning "></i>
                                        </span>
                                        <div class=" classContImg divSelect " type="button"
                                            (click)="modalViewDetailProduct( p)">
                                            <span *ngIf="p.precioOferta > 0"
                                                class="position-absolute top-0 start-0 translate-middle badge  badgeDesc "
                                                style="margin: 2.35em; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-bottom-left-radius: 0px;border-top-left-radius: 0px;">
                                                {{calculateDescount(p.precioOferta, p.precioReal )}} %
                                            </span>
                                            <img class="card-img-top "
                                                *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                                src="{{rutaUrl}}{{configuration.imgLogo}}?alt=media" alt=""
                                                style="object-fit: scale-down;  border-radius: 7px; opacity: 0.4;  ">
                                            <img class="card-img-top "
                                                *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                                src="{{rutaUrl}}{{p.imagenPrincipal}}?alt=media" alt="">

                                   
                                        </div>

                                        <div class="card-body"
                                            style="background-color: white;border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; ">
                                            <h5 class="card-title">{{p.pro_nom |uppercase}}</h5>
                                            <div class="d-flex justify-content-start " *ngIf="configuracion.mostrar_precio ==1" >
                                                <label class="labelStock"><strong>Disponible: </strong> {{p.stockactual
                                                    |
                                                    number:
                                                    '1.0' }} </label>
                                            </div>
                                            <div class="d-flex justify-content-between ">
                                                <!-- {{configurationVariables | json}} -->
                                                <p class="text-size-14 text-center sin-espacios"
                                                    (click)="modalViewDetailProduct(p)"
                                                    *ngIf="configuracion.mostrar_precio ==1">
                                                    <span style="font-family:'Helvetica' ; font-weight: bold; "
                                                        class="priceSpan"> $
                                                        {{p.precioReal | number: '1.2'}} </span>
                                                    <span class="text-secondary mr-2 text-subrayado-gray"
                                                        *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}}
                                                    </span>
                                                </p>
                                                <ng-container *ngIf="informacion.esPuntoVenta==1" >
                                                    <button *ngIf="!flagCarLocal" class="btn btn-sm rounded-2 btn-danger shadow"
                                                    (click)="setMethodAddCart(p, information.esPuntoVenta,tipoDefault)"
                                                    style=" height: 3.5em; width: 3.5em; ">
                                                    <i class="bi bi-cart-plus   fw-bold  "
                                                        style="font-size: 1.2em;"></i>
                                                </button>
                                                </ng-container>
                                                <ng-container *ngIf="informacion.esPuntoVenta==0" >
                                                    <button *ngIf="!flagCarLocal" class="btn btn-sm rounded-2  shadow"
                                                    (click)="setMethodAddCart(p, information.esPuntoVenta,tipoDefault)"
                                                    style=" height: 3.5em; width: 3.5em; background-color: rgb(250, 239, 218) " >
                                                    <i class="bi bi-cart-plus   fw-bold  icoCar "
                                                        style="font-size: 1.2em;"></i>
                                                </button>
                                                </ng-container>

                                                <button *ngIf="flagCarLocal" class="btn btn-sm rounded-2 btn-danger shadow"
                                                    style=" height: 3.5em; width: 3.5em;  "
                                                    (click)="makeComanda(p)">
                                                    <i class="bi bi-cart-plus   fw-bold text-white "
                                                        style="font-size: 1.2em;"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid bodyCatalogo" *ngIf="products.length==0 ">
                        <div style="margin-top: 10%;">
                            <div class="d-flex" style="justify-content: center; ">
                                <span style="font-size: x-large; font-weight: bold; " class="sinProds">
                                    CARGANDO..
                                </span>
                            </div>

                            <div class="d-flex" style="justify-content: center; ">
                                <i class="bi bi-archive sinProds" style="font-size: 5em; font-weight: bold; "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<app-scroll-to-top></app-scroll-to-top>

<!-- <app-footer></app-footer> -->


<ng-template #modalFinalCustomer class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-body p-3" style="font-family: 'Montserrat'" >
        <div class="row">
            <div class="col-10">
                <span  class="spanCard fw-bold">Elegir tipo de factura</span>
            </div>
            <div class="col-2" type="button" (click)="closeModal()">
                <i class="bi bi-x-lg icoForm"></i>
            </div>
        </div>
        <div class="row py-4 px-2">

           
            <div class="col-12 d-grid my-3" >
                <button class="btn btn-md btn-success  btn-block rounded-pill shadow"
                (click)="AddProductCarLocal()">
                Pagar en caja <i class="bi bi-currency-dollar"></i>
            </button>                
            </div>
            <div class="col-12 d-grid " >
                <button class="btn btn-md btn-warning  btn-block rounded-pill shadow"
                                (click)="setMethodAddCart(productDefault, 0,2)">
                                Compra en Línea <i class="bi bi-globe"></i>
                                <br>
                                <span class="fw-bold " style="font-weight: 500;">(Pago desde casa)</span>
                            </button>            
            </div>

        </div>
    </div>
    <!-- <div class="modal-header " style="text-align: center !important;">
        <div class="modal-body">
            <div class="col-lg-12 text-center mb-3">
                <div class="row">
                    <div class="col-10 text-size-14 text-size-16 text-secondary" style="font-weight: bold;">
                        Elegir tipo de factura
                    </div>
                    <div class="col-2">
                        <i class="bi bi-x-circle-fill text-danger" (click)="closeModal()"></i>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12   mb-2 ">
                            <button class="btn btn-sm btn-success  btn-block rounded-2 shadow"
                                (click)="AddProductCarLocal()">
                                Pagar en caja <i class="bi bi-currency-dollar"></i>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-sm btn-warning  btn-block rounded-2 shadow"
                                (click)="setMethodAddCart(productDefault, 0,2)">
                                Compra en Línea <i class="bi bi-globe"></i>
                                <br>
                                <span class="fw-bold " style="font-weight: 500;">(Pago desde casa)</span>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div> -->
</ng-template>

<!-- Modal Carrito Local -->
<ng-template #modalCarritoLocal class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-header " style="text-align: center !important;">

        <div class="modal-body">
            <div class="col-lg-12 text-center mb-3" style="position: relative;">
                <div class="row">
                    <div class="col-12 text-size-14 text-size-16 text-secondary" style="font-weight: bold;">
                        DETALLE ORDEN PEDIDO
                    </div>
                </div>
                <i class="bi bi-x-lg icoCarLocal"
                    style="position: absolute;top: 0; right: 0; font-size: 16px; font-weight: bold;  "
                    (click)="closeModal()"></i>
            </div>
                <div class="row" >
                    <div class="col-12  " id="viewLG" >
                        <div class="table-responsive mt-3" style="font-family: 'Montserrat';">
                            <table class="table table-hover  ">
                                <thead class="thead  table-secondary">
                                    <tr class="text-center ">
                                        <th class="columTable">Producto</th>
                                        <th class="columTable">Cantidad</th>
                                        <th class="columTable">Precio</th>
                                        <th class="columTable"><i class="bi bi-gear-fill"></i></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let prod of prods">
                                        <td style="font-size: 13px;">
                                            <span> {{prod.nombre_producto}} </span>
                                            <input type="text" class="form-control form-control-sm rounded-3"
                                                placeholder="Ingrese detalle del pedido" (input)="changeDetalle($event, prod)"
                                                [id]="prod.nombre_producto">
                                        </td>
                                        <td style="font-size: 13px;">
                                            <span>
                                                <button *ngIf="prod.cantidad>1" class="btnQuit"
                                                    style="border: none;  font-size: 22px; "
                                                    (click)="quitProduct(prod)">
                                                    <i class="bi bi-dash"></i>
                                                </button>
                                            </span>
                                            <span style="margin: 0px 5px;">
                                                {{prod.cantidad}}
                                            </span>
                                            <span>
                                                <button class="btnAdd" style="border: none; font-size: 22px; "
                                                    (click)="addProductCar(prod)">
                                                    <i class="bi bi-plus"></i>
                                                </button>
                                            </span>
                                        </td>
                                        <th style="font-size: 13px;">
                                            <span class=" columTable"> $ {{prod.valor * prod.cantidad | number:'1.2-2'
                                                }}
                                            </span>
                                        </th>
                                        <td>
                                            <div class="button-group button-group-sm ">
                                                <button class="btn btn-sm rounded-1 btnEraseCarLocal"
                                                    style="border: none;  font-size: 16px;"
                                                    (click)="quitProductComanda(prod)">
                                                    <i class="bi bi-trash3"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style="background-color: #f2f2f4 !important;">
                                        <th></th>
                                        <th class="text-center columTable">TOTAL </th>
                                        <th class="text-center columTable ">$ {{totalComanda(prods) | number:'1.2-2'}}
                                        </th>
                                        <th></th>
                                    </tr>

                                </tbody>

                            </table>

                        </div>
                    </div>
                    <div class=" col-12" id="viewSM">
                        <div class="card">
                            <div class="row">
                                <div class="col-12  mb-2 " *ngFor="let prod of prods">
                                    <label class="fw-bold" style="text-align: left; float: left;">Producto: </label>
                                    
                                    <span class="pl-1" > {{ prod.nombre_producto}} </span>
                                    <input type="text" class="form-control form-control-sm rounded-1"
                                        placeholder="Ingrese detalle" (input)="changeDetalle($event, prod)"
                                        [id]="prod.nombre_producto">
                                        <div class="table-responsive mt-3" style="font-family: 'Montserrat';">
                                            <table class="table table-hover   ">
                                                <thead class="thead">
                                                    <tr class="text-center ">
                                                        <th class="columTable" >Cantidad</th>
                                                        <th class="columTable">Precio</th>
                                                        <th class="columTable"><i class="bi bi-gear-fill"></i></th>
                                                    </tr>
                
                                                </thead>
                                                <tbody>
                                                    <tr class="text-center" >

                                                        <td style="font-size: 13px;">
                                                            <span>
                                                                <button *ngIf="prod.cantidad>1" class="btnQuit"
                                                                    style="border: none;  font-size: 22px; "
                                                                    (click)="quitProduct(prod)">
                                                                    <i class="bi bi-dash"></i>
                                                                </button>
                                                            </span>
                                                            <span style="margin: 0px 5px;">
                                                                {{prod.cantidad}}
                                                            </span>
                                                            <span>
                                                                <button class="btnAdd" style="border: none; font-size: 22px; "
                                                                    (click)="addProductCar(prod)">
                                                                    <i class="bi bi-plus"></i>
                                                                </button>
                                                            </span>
                                                        </td>
                                                        <th style="font-size: 13px;">
                                                            <span class=" columTable"> $ {{prod.valor * prod.cantidad | number:'1.2-2'
                                                                }}
                                                            </span>
                                                        </th>
                                                        <td>
                                                            <div class="button-group button-group-sm ">
                                                                <button class="btn btn-sm rounded-1 btnEraseCarLocal"
                                                                    style="border: none;  font-size: 16px;"
                                                                    (click)="quitProductComanda(prod)">
                                                                    <i class="bi bi-trash3"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>

                        <div class="p-2 m-2" >
                            <tr style="background-color: #e7fedc !important;">
                                <th></th>
                                <th class="text-center columTable">TOTAL </th>
                                <th class="text-center columTable ">$ {{totalComanda(prods) | number:'1.2-2'}}
                                </th>
                                <th></th>
                            </tr>
                        </div>

                    </div>
                </div>



                <div class="row divSelect" *ngIf="!flagMakeComanda">
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6   text-center mt-1 mb-1 d-grid gap-2">
                        <button type="button" class="btn btn-sm btn-success rounded-pill btn-block" id="btn-cart"
                            (click)="closeModal()">
                            Añadir más <i class="bi bi-bag-plus-fill"></i>
                        </button>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6  text-center mt-1 mb-1 d-grid gap-2">
                        <button type="button" class="btn btn-secondary btn-sm rounded-pill btn-block "
                            (click)="emptyCar()">
                            Vaciar carrito
                            <i class="bi bi-cart-x-fill ml-2"></i>
                        </button>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6  text-center mt-1 mb-1 d-grid gap-2">
                        <button *ngIf="information.esPuntoVenta==1" type="button" (click)="hacerComanda()"
                            class="btn btn-sm btn-danger rounded-pill btn-block ">
                            Pagar en Caja <i class="fas fa-store ml-2"></i>
                        </button>
                    </div>

                </div>
                <div *ngIf="flagMakeComanda">
                    <hr>
                    <div class="col-12 text-size-14 text-size-16 text-secondary" style="font-weight: bold;">
                        NOMBRE DE QUIEN RETIRA EL PEDIDO
                    </div>
                    <div class="card shadow-md rounded-2 p-3 " style="background-color: #f2f2f4;">
                        <form [formGroup]="formComanda">
                            <div class="row mb-3">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  ">
                                    <div class="contenido3">
                                        <label for="">Contacto</label>
                                        <input formControlName="contacto" placeholder="Ingrese contacto" type="text"
                                            class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  ">
                                    <div class="contenido3">
                                        <label for="">Forma de Pago</label>
                                        <select disabled formControlName="pago"
                                            class="custom-select custom-select-sm rounded-1 ">
                                            <ng-container *ngFor="let tp of tiposPago">
                                                <option [value]=tp.cod>{{tp.nombre}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: none;">
                                    <div class="contenido3">
                                        <label for="">Calle Principal</label>
                                        <input formControlName="principal" type="text"
                                            class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: none;">
                                    <div class="contenido3">
                                        <label for="">Calle Secundaria</label>
                                        <input formControlName="secundaria" type="text"
                                            class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: none;">
                                    <div class="contenido3">
                                        <label for="">Referencia </label>
                                        <input formControlName="referencia" type="text"
                                            class="form-control form-control-sm">
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="container divSelect">
                            <div class="col-xxl-8 col-xl-8 col-lg-10 col-12  ">
                                <div class="row ">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                                        <!-- <button class="btn btn-sm rounded-1 btn-block btnPedido" [disabled]="formComanda.invalid" >
                                            Hacer Pedido
                                        </button> -->
                                        <button class="btn btn-sm rounded-pill btn-block btnPedido mt-2 "
                                            (click)="comandaFinal(formComanda.value)"
                                            [disabled]="formComanda.invalid || banderaAC  ">
                                            <i [ngClass]="{'fa-check':!banderaAC, 'fa-rotate fa-spinner':banderaAC}"
                                                class="fa"> </i>
                                            <span *ngIf="!banderaAC"> Realizar Pedido</span>
                                            <span *ngIf="banderaAC"> Realizando Pedido....</span>
                                        </button>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                                        <button class="btn btn-sm rounded-pill btn-block btnPedidoCancel  mt-2"
                                            (click)="returnPagoCar()">
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </div>
</ng-template>

<!-- Modal Ticket Comanda -->
<ng-template #modalPrintComanda class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-header ">
    </div>
    <div class="" id="ticket">
        <h6 class="fw-bold" style=" font-size: 13px; text-align: center; ">
            ORDEN PEDIDO #{{idComanda}}
        </h6>
        <div>
            <label for="" style="display: flex; justify-content: start; margin-left: 4px; "> <strong
                    style="margin-right: 3px; ">Retira: </strong> {{ contactoFinal}} </label>
        </div>
        <div>
            <h6 class="fw-bold" style=" font-size: 13px; text-align: center; font-weight: 500; ">
                DETALLE ORDEN
            </h6>
            <div class="table-responsive mt-3" style="font-family: 'Montserrat';font-size: 12px; ">
                <table class="table table-hover   ">
                    <thead class="thead">
                        <tr class="text-center ">
                            <th style="font-family: 'Montserrat';font-size: 12px; ">PRODUCTO</th>
                            <th style="font-family: 'Montserrat';font-size: 12px; ">CANT.</th>
                            <th style="font-family: 'Montserrat';font-size: 12px; ">PRECIO</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr class="text-center" *ngFor="let prod of prods">
                            <td style="font-size: 13px;">
                                {{prod.nombre_producto}}
                            </td>
                            <td style="font-size: 13px;">

                                <span style="margin: 0px 5px;">
                                    {{prod.cantidad}}
                                </span>

                            </td>
                            <th style="font-size: 13px;">
                                <span class=" columTable"> $ {{prod.valor * prod.cantidad | number:'1.2-2' }}
                                </span>
                            </th>

                        </tr>
                        <tr style="background-color: #f2f2f4 !important;">
                            <th></th>
                            <th class="text-center " style="font-family: 'Montserrat';font-size: 12px; ">TOTAL </th>
                            <th class="text-center " style="font-family: 'Montserrat';font-size: 12px; ">$
                                {{totalComanda(prods) | number:'1.2-2'}}</th>
                            <th></th>
                        </tr>

                    </tbody>
                </table>
                <div class="" style="text-align: center; margin-top: 5px;">
                    <label for="">
                        Gracias por su compra!!
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="divSelect mb-3" style="display: flex ; justify-content: center; align-items: center;  margin-top: 5px;">
        <button class="btn btn-sm btn-success rounded-1 shadow-md" printSectionId="ticket" ngxPrint
            (click)="closeFinal()">
            Imprimir
        </button>

    </div>

</ng-template>

<app-loader [flagLoader]="flagLoader"></app-loader>