<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<section>
    <div class="container-fluid">

        <div class="row d-flex d-md-block flex-nowrap wrapper">
            <div class="col-lg-2 float-left col-1 pl-0 pr-0 collapse width show" id="sidebar">
                <div class="list-group border-0 text-center text-md-left text-minuscula-texto-negro text-size-13">

                    <a class="list-group-item d-inline-block collapsed tipo-cursor a-menu" (click)="goHome()">
                        <i class="fas fa-house-user text-size-18"></i>
                        <span class="d-none d-md-inline">Inicio</span>
                    </a>

                    <hr>

                    <a (click)="selectMenu('miPerfil')" class="list-group-item d-inline-block collapsed tipo-cursor a-menu">
                        <i class="fas fa-user-tie"></i>
                        <span class="d-none d-md-inline">Mi perfil</span>
                    </a>

                    <a href="#menu2" class="list-group-item d-inline-block collapsed sin-subrayado opc-menu"
                        data-toggle="collapse" aria-expanded="false">
                        <i class="bi bi-cart-x-fill"></i>
                        <span class="d-none d-md-inline">Mis compras</span>
                    </a>

                    <div class="collapse" id="menu2">

                        <a class="list-group-item ml-2 tipo-cursor opc-menu a-menu" (click)="selectMenu('comprasPendientes')">
                            <i class="fas fa-hand-paper text-warning"></i>
                            <span class="d-none d-md-inline">Pendientes</span>
                        </a>

                        <a class="list-group-item ml-2 tipo-cursor opc-menu a-menu" (click)="selectMenu('comprasProcesadas')">
                            <i class="fas fa-thumbs-up text-success"></i>
                            <span class="d-none d-md-inline">Procesadas</span>
                        </a>

                    </div>

                    <hr>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor a-menu" (click)="goHome()">
                        <i class="fas fa-arrow-circle-left"></i>
                        <span class="d-none d-md-inline">Regresar</span>
                    </a>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor a-menu" (click)="signOff()">
                        <i class="fas fa-lock text-danger"></i>
                        <span class="d-none d-md-inline">Cerrar Sesión</span>
                    </a>

                </div>
            </div>
        </div>

        <main class="col-lg-10 float-left pl-5 w-100 text-minuscula-form">

            <br>

            <div class="container" *ngIf="menu.miPerfil">

                <div class="form-group text-size-13">
                    <label for="cedula">Cédula/Ruc/Pasaporte</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                        disabled [(ngModel)]="user.PersonaComercio_cedulaRuc">
                </div>

                <div class="form-group text-size-13">
                    <label for="nombre">Nombres</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                        [(ngModel)]="user.nombres">
                </div>

                <div class="form-group text-size-13">
                    <label for="apellidos">Apellidos</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                        [(ngModel)]="user.apellidos">
                </div>

                <div class="form-group text-size-13">
                    <label for="email">Correo Electrónico</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                        [(ngModel)]="user.email">
                </div>

                <div class="form-group text-size-13">
                    <label for="telefonos">Celular</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                        [(ngModel)]="user.celular">
                </div>

                <div class="form-group text-size-13">
                    <label class="text-size-14 mb-3">Provincia</label>
                    <br>
                    <select class="form-select form-select-sm border w-50 pl-2 pt-1 pb-1" [(ngModel)]="user.provincia" (change)="getCantonesProvince(user.provincia)">
                        <option class="text-size-12" *ngFor="let p of provinces" value="{{p.idProvincia}}"> {{p.descripProv}}</option>
                    </select>
                </div>

                <div class="form-group text-size-13" *ngIf="cantones.length > 0">
                    <label class="text-size-14 mb-3">Cantón</label>
                    <br>
                    <select class="form-select form-select-sm border w-50 pl-2 pt-1 pb-1" [(ngModel)]="user.canton">
                        <option class="text-size-12" *ngFor="let c of cantones" value="{{c.idCanton}}"> {{c.descripCtn}}</option>
                    </select>
                </div>

                <div class="form-group text-size-13">
                    <label for="direccion">Dirección</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" [(ngModel)]="user.direccion">
                </div>

                <div class="form-group text-size-13">
                    <label for="referencia">Referencia</label>
                    <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" [(ngModel)]="user.referencia_domicilio">
                </div>

                <br>

                <div class="form-group text-center w-100">
                    <button type="button" class="btn btn-dark" (click)="updateUser(user)">
                        <i class="bi bi-floppy-fill"></i>
                    </button>
                </div>

                <br>
            </div>

            <div class="container" *ngIf="menu.comprasPendientes">
                <div class="row form-group">
                    <div class="col-lg-12 table-responsive">
                        <table class="table table-hover text-size-13">
                            <thead>
                                <tr class="text-center text-minuscula-form">
                                    <th scope="col">id</th>
                                    <th scope="col">Fecha Emision</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Ver</th>
                                    <th scope="col">Cancelar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of cotizationsPending" class="text-size-11 text-normal text-center">
                                    <th>{{c.id}}</th>
                                    <td>{{c.fechaCreacion}}</td>
                                    <td>{{c.totalCompra | number : '1.2'}}</td>
                                    <td (click)="modalShowDetailCotization(showDetailCotizationModal ,c)">
                                        <i class="fas fa-eye text-size-14"></i>
                                    </td>
                                    <td (click)="modalDeleteFile(deleteFileModal, c, 'cotization')">
                                        <i class="bi bi-x-lg-circle text-size-14 text-danger" aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="container" *ngIf="menu.comprasProcesadas">
                <div class="row form-group">
                    <div class="col-lg-12 table-responsive">
                        <table class="table table-hover text-size-13">
                            <thead>
                                <tr class="text-center text-minuscula-form">
                                    <th scope="col">id</th>
                                    <th scope="col">Fecha Emision</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of cotizationsProcessed" class="text-size-11 text-normal text-center">
                                    <th scope="row">{{c.id}}</th>
                                    <td>{{c.fechaCreacion}}</td>
                                    <td>{{c.totalCompra | number : '1.2'}}</td>
                                    <td (click)="modalShowDetailCotization(showDetailCotizationModal ,c)">
                                        <i class="fas fa-eye text-size-14"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </main>
    </div>
</section>

<!-- Alerta para eliminar un archivo -->
<ng-template #deleteFileModal let-modal>
    <div class="row pt-3 tipo-letra text-size-12">
        <div class="col-12 text-center text-size-13 alert alert-danger" role="alert">
            <span>{{messageDelete.cotization}}</span>
        </div>
    </div>

    <div class="row modal-body tipo-letra text-center">
        <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-success text-center mr-3" (click)="modal.close()">
                <i class="bi bi-check-circle-fill"></i>
            </button>
            <button type="button" class="btn btn-outline-danger text-center" (click)="modal.dismiss('Cross click')">
                <i class="bi bi-dash-lg-circle"></i>
            </button>
        </div>
    </div>
</ng-template>

<!-- Alerta para mostrar el detalle de la cotizacion-->
<ng-template #showDetailCotizationModal let-modal>

    <div class="bg-light rounded">

        <div class="row mt-3 text-normal">

            <div class="col-10 text-left">
                <p class="text-size-14 ml-4"><strong>Pedido Nro. {{cotizationsSelected.id}}</strong></p>
            </div>

            <div class="col-2">
                <i class="bi bi-x-circle-fill text-danger" (click)="modal.dismiss('Cross click')"></i>
            </div>

        </div>

        <hr class="mt-0">

        <div class="modal-body pt-0 text-minuscula">

            <div class="row text-size-14">

                <div class="col-lg-8">
                    <span class="text-normal text-size-13">Cliente: </span>
                    <span>{{cotizationsSelected.nombres_cliente}} {{cotizationsSelected.apellidos_cliente}}</span>
                    <br>
                    <span class="text-normal text-size-13">Correo: </span>
                    <span>{{cotizationsSelected.email_cliente}}</span>
                </div>

                <div class="col-lg-4">
                    <span class="text-normal text-size-13">CI/Ruc: </span>
                    <span>{{cotizationsSelected.cliente_cedulaRuc}}</span>
                    <br>
                    <span class="text-normal text-size-13">Celular: </span>
                    <span>{{cotizationsSelected.celular_cliente}}</span>
                </div>

            </div>

            <br>

            <div class="row">
                <div class="col-lg-12 table-responsive">
                    <table class="table table-hover text-size-13">
                        <thead class="thead-light">
                            <tr class="text-center">
                                <th class="text-left" scope="col">Producto</th>
                                <th scope="col">Cant.</th>
                                <th scope="col">P. Unitario</th>
                                <th scope="col">P. Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of cotizationsSelected.productos"
                                class="text-size-11 text-normal text-center">
                                <td class="text-left">{{p.pro_nom}}</td>
                                <td>{{p.itemcantidad}}</td>
                                <td>{{p.itempreciobruto}}</td>
                                <td>{{p.itemprecioxcantidadneto}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr>

            <div class="row text-size-14 ml-3 mr-3">
                <div class="col-lg-12 text-right">
                    
                    <p class="m-0">
                        <span class="pr-3">Subtotal 12%:</span>
                        <span>{{cotizationsSelected.tarifadocebruto}}</span>
                    </p>
                    <p class="m-0">
                        <span class="pr-3">Subtotal 0%:</span>
                        <span>{{cotizationsSelected.tarifacerobruto}}</span>
                    </p>
                    <p class="m-0">
                        <span class="pr-3">Subtotal:</span>
                        <span>{{cotizationsSelected.subtotalBruto}}</span>
                    </p>
                    <p class="m-0">
                        <span class="pr-3">Iva:</span>
                        <span>{{cotizationsSelected.ivaval}}</span>
                    </p>
                    <p class="bg-secondary text-light">
                        <strong>
                            <span class="pr-3"><strong>Total:</strong></span>
                            <span>{{cotizationsSelected.totalCompra}}</span>
                        </strong>
                    </p>

                </div>
            </div>

        </div>

    </div>

</ng-template>

<app-scroll-to-top></app-scroll-to-top>
